import React from 'react'

import Layout from '../../components/Layout'
import FaqsNavBar from '../../components/FaqsNavBar'

const metaData = [
  {
    name: 'description',
    content: 'FAQ',
  },
  {
    name: 'keywords',
    content:
      'perdicure chair, nail, nail furniture, ask us, nail salon furniture, nail chair',
  },
  { name: 'author', content: 'Contego Spa Design' },
]

const IndexPage = () => {
  return (
    <Layout
      title="FAQ | Contego Spa Designs | Pedicure Spa Chair"
      metaData={metaData}
    >
      <FaqsNavBar />
    </Layout>
  )
}

export default IndexPage
